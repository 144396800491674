import React, { Component } from "react";

//Import CSS

//Import internal components
import AppContext from "../../../appContext";
import CRMSwitch from "../../../crm/crm-commons/crm-switch";
import Input from "../../../components/inputs/input";
import Select from "../../../components/selects/select";
import CustomButton from "../../../components/buttons/button";

//Import external components
import Alert from "sweetalert2";
import Swal from "sweetalert2";
import { cpf } from "cpf-cnpj-validator";
import { Redirect } from "react-router";

//Import images

//Import icons
import { Error } from "@material-ui/icons";

class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exemplo: "",
      otica: "",
      laboratorio: "",
      redeOticas: false,
      result: [],
      promo_code: "",
      dados_promo_nome: "",
      voucher_ok: this.props.order.voucher_ok ? this.props.order.voucher_ok : false,
      voucher_nok: false,
      used_voucher: false,
      invalid_voucher: false,
      loop_flag: true,
      loop_flag_loading: true,
      has_fidelity: false,
      pares: [],
      cancel_voucher: false,
    };

    OrderDetail.contextType = AppContext;
    this.hasPromo = this.hasPromo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getPromoData = this.getPromoData.bind(this);
    this.applyVoucher = this.applyVoucher.bind(this);
    this.onBlurPromo = this.onBlurPromo.bind(this);
  }

  componentDidMount() {
    this.context.showLoading();
    var fidelidade = false;
    const { order } = this.props;

    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (this.context.userSession.redeOticas) {
          this.setState({ otica: json.nome, cnpjOticaPrincipal: json.cnpj }, this.listarRedesOticas());
        } else if (!order.dados_empresa_faturamento) {
          this.setState({ otica: json.nome }, () => {
            this.props.handleOrderChange(
              {
                dados_empresa_faturamento: json.nome,
                dados_empresa_faturamento_cnpj: json.cnpj,
              },
              this.getPromoData(),
            );
          });
        }
      });

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        cnpjs: this.props.laboratorio,
        papel: this.context.userSession.papel,
        conta: this.context.userSession.conta,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_labs.php", requestOptions)
      .then(response => {
        if (!this.context.userSession.redeOticas) {
          this.context.hideLoading();
        }
        return response.json();
      })
      .then(json => {
        if (json.items) {
          fidelidade = json.items[0].PossuiProgramaFidelidadeProprio_c;
        }

        this.setState({ has_fidelity: fidelidade });
      });
  }

  componentDidUpdate() {
  
    if (!this.props.order.promo) {
      this.cleanPromo();
    }
    if (this.state.loop_flag_loading && this.props.order.promo_proximo_par > 1) {
      this.context.showLoading("Carregando Cupom");
      this.setState({ loop_flag_loading: false });
    }

    if (this.state.loop_flag) {
      if (this.props.order.promo && this.props.order.promo_proximo_par > 1) {
        if (
          this.props.order.promo_code !== "" &&
          this.props.order.promo_code !== undefined &&
          this.props.laboratorio !== undefined &&
          this.props.order.dados_empresa_faturamento_cnpj !== undefined
        ) {
          this.setState({ loop_flag: false });
        }
      }
    }
  }

  hasPromo() {
    return this.context.userSession.modulos.includes("Pedidos Promoção");
  }

  handleChange = evt => {
    let tamanho = evt.target.value.length;
    // TK-083-000318 [Pedidos] Limitar campo Nome do cliente
    if (evt.target.name === "dados_nome_cliente" || evt.target.name === "dados_nome_medico") {
      evt.target.value = evt.target.value.substring(0, 50);
    }
      document.getElementById(evt.target.id).style.boxShadow = "0 0 0 1px #B5B5B5";

      if (evt.target.name === "dados_cpf_cliente") {
        this.props.handleOrderChange({
          dados_cpf_cliente_mask: evt.target.value,
        });
      }

      //Renata solicitou para liberar que pedido seja incluido com ponto (SPR28-116)
      // var value = evt.target.value.replace(".", "").replace(".", "").replace("-", "");
      var value = evt.target.value;
      if (evt.target.name === "dados_empresa_faturamento") {
        if (this.context.userSession.modulos.includes("Consulta Cupom")) {
          this.props.handleOrderChange({
            dados_empresa_faturamento_cnpj: evt.target.value,
          });
          value = evt.target.value;
        } else if (this.state.redeOticas) {
          this.setState({
            otica: evt.target.options[evt.target.selectedIndex].text,
          });
          value = evt.target.options[evt.target.selectedIndex].text;
          this.props.handleOrderChange({
            dados_empresa_faturamento_cnpj: evt.target.value,
          });
        } else {
          value = evt.target.value;
        }
      }

      if (evt.target.name === "dados_email") {
        value = evt.target.value;
      }
      
      if(evt.target.name === 'promo_code'){
         value = evt.target.value.trim();
      }
      this.props.handleOrderChange({ [evt.target.name]: value });
    
  };

  handleSwicthChange = () => {
    // this.props.handleOrderChange({ addCrm: !this.props.order.addCrm });
  };

  handleClickNext = () => {
    //Função para passar para a próxima etapa do pedido

    // confere campos obrigatórios quando CRM está ativado
    // if (this.props.order.addCrm) {
    //   var valido = true;
    //   var msg = "Campos obrigatórios pendentes!";

    //   //Confere se os campos do CRM obrigatórios estão preenchidos e pinta eles de vermelho

    //   if (this.state.redeOticas && (!this.props.order.dados_empresa_faturamento_cnpj || this.props.order.dados_empresa_faturamento_cnpj === undefined)) {
    //     document.getElementById("dados_empresa_faturamento").style.boxShadow = "0 0 0 1px #f70017";
    //     valido = false;
    //   }

    //   if (!this.props.order.dados_num_pedido) {
    //     document.getElementById("dados_num_pedido").style.boxShadow = "0 0 0 1px #f70017";
    //     valido = false;
    //   }

    //   if (!this.props.order.dados_nome_cliente) {
    //     document.getElementById("dados_nome_cliente").style.boxShadow = "0 0 0 1px #f70017";
    //     valido = false;
    //   }

    //   if (!this.props.order.dados_email && !this.props.order.dados_telefone) {
    //     document.getElementById("dados_email").style.boxShadow = "0 0 0 1px #f70017";
    //     document.getElementById("dados_telefone").style.boxShadow = "0 0 0 1px #f70017";
    //     valido = false;
    //   }

    //   if (!this.props.order.dados_cpf_cliente) {
    //     document.getElementById("dados_cpf_cliente").style.boxShadow = "0 0 0 1px #f70017";
    //     valido = false;
    //   } else {
    //     if (!cpf.isValid(this.props.order.dados_cpf_cliente)) {
    //       document.getElementById("dados_cpf_cliente").style.boxShadow = "0 0 0 1px #f70017";
    //       msg = "CPF inválido.";
    //       valido = false;
    //     }
    //   }

    //   // Se todos os campos obrigatórios estiverem preenchidos
    //   if (valido) {
    //     // Se tiver código promocional no order, antes de ir pra próxima etapa
    //     if (this.props.order.promo_code) {
    //       if (this.state.promo_nok) {
    //         // Confere se o cupom é válido
    //         if (this.state.invalid_voucher) {
    //           Alert.fire({
    //             text: "Cupom inválido.",
    //             icon: "warning",
    //             title: "Aviso",
    //             confirmButtonColor: "#00518b",
    //             customClass: {
    //               confirmButton: "x-follow-order__filter-btn",
    //             },
    //           });
    //         }

    //         // Confere se o cupom ainda pode ser utilizado
    //         else if (this.state.used_voucher || this.state.cancel_voucher) {
    //           Alert.fire({
    //             text: this.state.cancel_voucher ? "Cupom cancelado." : "Cupom já utilizado.",
    //             icon: "warning",
    //             title: "Aviso",
    //             confirmButtonColor: "#00518b",
    //             customClass: {
    //               confirmButton: "x-follow-order__filter-btn",
    //             },
    //           });
    //         }
    //       }

    //       // Se o cupom for válido e não utilizado, passa pra próxima etapa
    //       else {
    //         if (this.props.order.promo_code && !this.state.voucher_ok) {
    //           document.getElementById("promo_code").style.boxShadow = "0 0 0 1px #f70017";
    //           Alert.fire({
    //             html: "Cupom não aplicado. <br> Para prosseguir, aplique ou apague o cupom.",
    //             icon: "warning",
    //             title: "Aviso",
    //             confirmButtonColor: "#00518b",
    //             customClass: {
    //               confirmButton: "x-follow-order__filter-btn",
    //             },
    //           });
    //         } else {
    //           this.props.onNext(2, "right");
    //         }
    //       }
    //     }

    //     // Se não tem número de cupom no order
    //     else {
    //       this.props.onNext(2, "right");
    //     }
    //   }
    //   // Campos obrigatórios não preenchidos, mostra mensagem.
    //   else {
    //     Alert.fire({
    //       text: msg,
    //       icon: "warning",
    //       title: "Aviso",
    //       confirmButtonColor: "#00518b",
    //       customClass: {
    //         confirmButton: "x-follow-order__filter-btn",
    //       },
    //     });
    //     return;
    //   }
    // }

    // // Quando não tem CRM ativado
    // else {
    // Confere se o campo de n° pedido ótica está preenchido
    if (this.props.order.dados_num_pedido) {
      // Se tiver código promocional no order, antes de ir pra próxima etapa
      if (this.props.order.promo_code) {
        if (this.state.voucher_nok) {
          // Confere se o cupom é válido
          if (this.state.invalid_voucher) {
            Alert.fire({
              text: "Cupom inválido.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }

          // Confere se o cupom ainda pode ser utilizado
          else if (this.state.used_voucher || this.state.cancel_voucher) {
            Alert.fire({
              text: this.state.cancel_voucher ? "Cupom cancelado." : "Cupom já utilizado.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }
        }

        // Se o cupom for válido e não utilizado, passa pra próxima etapa
        else {
          if (this.props.order.promo_code && !this.state.voucher_ok) {
            document.getElementById("promo_code").style.boxShadow = "0 0 0 1px #f70017";
            Alert.fire({
              html: "Cupom não aplicado. <br> Para prosseguir, aplique ou apague o cupom.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          } else {
            if (this.state.pares.length > 1 && this.state.pares[0].id_pedido_otica === "ORDER_UNUSED") {
              var objCupom = {
                pares: this.state.pares,
                cupom: this.state.promo_code,
                num_pedido: document.getElementById("dados_num_pedido").value,
                consumidor_nome: document.getElementById("dados_nome_cliente").value,
                consumidor_cpf: document.getElementById("dados_cpf_cliente").value,
                medico_nome: document.getElementById("dados_nome_medico").value,
                medico_crm: document.getElementById("dados_crm_medico").value,
              };

              if (localStorage.getItem("info_compl")) {
                const stgItem = JSON.parse(localStorage.getItem("info_compl"));
                if (stgItem.filter(el => el.cupom === this.state.promo_code).length > 0) {
                  let result = stgItem.find(el => el.cupom === this.state.promo_code);
                  result = objCupom;
                  const newArray = stgItem.filter(el => el.cupom !== this.state.promo_code);
                  newArray.push(result);
                  localStorage.setItem("info_compl", JSON.stringify(newArray));
                } else {
                  stgItem.push(objCupom);
                  localStorage.setItem("info_compl", JSON.stringify(stgItem));
                }
              } else {
                localStorage.setItem("info_compl", JSON.stringify([objCupom]));
              }
            }
            this.props.onNext(2, "right");
          }
        }
      }

      // Se não tem número de cupom no order
      else {
        if (this.state.redeOticas && (!this.props.order.dados_empresa_faturamento_cnpj || this.props.order.dados_empresa_faturamento_cnpj === undefined)) {
          document.getElementById("dados_empresa_faturamento").style.boxShadow = "0 0 0 1px #f70017";
          Alert.fire({
            text: "Selecione uma ótica para o pedido.",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        } else {
          this.props.onNext(2, "right");
        }
      }
    }

    // Campos obrigatórios não preenchidos, mostra mensagem.
    else {
      document.getElementById("dados_num_pedido").style.boxShadow = "0 0 0 1px #f70017";
      Alert.fire({
        text: "Campos obrigatórios pendentes!",
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
      return;
    }
    // }
  };

  listarRedesOticas = cnpjOtica => {
    this.context.showLoading();
    if (this.context.userSession.modulos.includes("Consulta Rede")) {
      this.setState({ placeholder: "Selecione uma ótica" });
      const { laboratorio } = this.props;

      const requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: laboratorio,
        },
      };

      fetch(process.env.REACT_APP_ESSILOR_API + "gestao-laboratorios/v1/redes", requestOptions).then(response => {
        if (response.status === 403) {
          Swal.fire({
            title: "Aviso",
            text: "Laboratório não disponível para este usuário no momento.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.value) {
              this.setState({ redirect: true });
            }
          });
        } else {
          /*if (response.status !== 200 && response.status !== 404) {
           /* Swal.fire({
              title: "Aviso",
              text: "Não foi possível verificar se esta ótica pertence a um grupo dentro do laboratório.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            }).then(result => {});
            var redes = [];
            var rede = {
              label: this.state.otica.nome,
              value: this.context.userSession.conta,
            };

            redes.push(rede);
        
            this.setState({ redeOticas: false,redes: redes }, () => {
            this.props.handleOrderChange({
              dados_empresa_faturamento: this.state.otica,
              dados_empresa_faturamento_cnpj: this.context.userSession.conta,
              redeOticas: false,
            });
          });
          
          }
*/
          return response.json().then(json => {
            if (json.participantes) {
              const redes = [];
              json.participantes.map((value, index) => {
                if (value.cnpj === this.state.cnpjOticaPrincipal && !this.props.order.dados_empresa_faturamento) {
                  this.setState({ otica: value.descricao, redeOticas: true }, () => {
                    this.props.handleOrderChange({
                      dados_empresa_faturamento: value.descricao,
                      dados_empresa_faturamento_cnpj: value.cnpj,
                      redeOticas: true,
                    });
                  });
                }

                var rede = {
                  label: value.descricao,
                  value: value.cnpj,
                };

                redes.push(rede);
              });
              this.setState({
                redes: redes,
                redeOticas: true,
              });
              this.getPromoData();
            } else {
              this.setState({ redeOticas: false }, () => {
                this.props.handleOrderChange({
                  dados_empresa_faturamento: this.state.otica,
                  redeOticas: false,
                });
              });
              this.getPromoData();
            }
          });
        }
      });
    } else {
      this.setState({ redeOticas: false }, () => {
        this.props.handleOrderChange({
          dados_empresa_faturamento: this.state.otica,
          redeOticas: false,
        });
      });

      this.getPromoData();
    }
  };

  cleanPromo() {
    this.myFormRef.reset();
    localStorage.removeItem("info_compl");
    this.setState({
      promo_ok: false,
      promo_nok: false,
      invalid_voucher: false,
      used_voucher: false,
      cancel_voucher: false,
    });

    this.props.handleOrderChange({
      descricao:"",
      lente_corteLabel: "",
      tratamentoLabel: "",

      rpl: "",
      promo_ok: false,
      tooltip_msg: "",

      dados_num_pedido: "",
      dados_nome_cliente: "",
      dados_cpf_cliente: "",
      dados_cpf_cliente_mask: "",
      dados_nome_medico: "",
      dados_crm_medico: "",

      lente_tp_producao: "",
      lente_tp_producao_cod: "",
      lente_tp_producao_value: "",
      lente_tp_produto: "",
      lente_tp_produto_cod: "",
      lente_tp_produto_value: "",
      lente_esc_produto_nome: "",
      lente_servicos: "",
      lente_servicos_cod: "",
      lente_servicos_value: "",
      lente_coloracao: "",
      lente_coloracao_cod: "",
      lente_coloracao_value: "",
      cor: "",
      cor_cod: "",
      cor_value: "",
      lente_corte: "",
      lente_corte_cod: "",
      lente_corte_value: "",
      lente_montagem: "S",
      lente_montagem_value: "Sim",
      json_parametros: [],

      prescricao_olho_direito_esferico: "",
      prescricao_olho_direito_cilindrico: "",
      prescricao_olho_direito_eixo: "",
      prescricao_olho_direito_adicao: "",
      prescricao_olho_direito_dnp: "",
      prescricao_olho_direito_altura: "",

      prescricao_olho_esquerdo_esferico: "",
      prescricao_olho_esquerdo_cilindrico: "",
      prescricao_olho_esquerdo_eixo: "",
      prescricao_olho_esquerdo_adicao: "",
      prescricao_olho_esquerdo_dnp: "",
      prescricao_olho_esquerdo_altura: "",

      prescricao_ini_clientes: "",
      prescricao_dis_leitura: "",
      prescricao_cvp: "",
      prescricao_cro_direito: "",
      prescricao_cro_esquerdo: "",
      prescricao_ang_pantoscopico: "",
      prescricao_curva_arm: "",
      prescricao_coeficiente: "",
      prescricao_estabilidade: "",
      prescricao_dis_prog: "",
      prescricao_dis_vert_direito: "",
      prescricao_dis_vert_esquerdo: "",
      prescricao_olho_dom: "",
      descricao: "",

      codigoRefServicos: "",
      arValue: [],
      colValue: [],
      corValue: [],
      cores:[],
      cor_obrigatoria:false,

      promo_n_par: null,
      promo_proximo_par: null,
      promo_total_pares: 0,
      dados_promo_nome: "",
      dados_email: "",
      dados_nascimento: "",
      dados_telefone: "",
      pedidoColorirNaOtica:false,
      cor_armacao_value:'',
      cor_armacao:'',
      cores_armacao:[],
      marcas_armacao:[],
      marca_armacao:'',
      marca_armacao_value:'',
    });

    if (this.props.order.prescricao_prisma === "S") {
      this.props.handleOrderChange({
        prescricao_prisma: "N",
        prescricao_prisma_value: "Não",
        prescricao_olho_esquerdo_pris_h: "",
        prescricao_olho_esquerdo_pris_h_value: "",
        prescricao_olho_esquerdo_base_h: "",
        prescricao_olho_esquerdo_base_h_value: "",
        prescricao_olho_esquerdo_pris_v: "",
        prescricao_olho_esquerdo_pris_v_value: "",
        prescricao_olho_esquerdo_base_v: "",
        prescricao_olho_esquerdo_base_v_value: "",
        prescricao_olho_direito_pris_h: "",
        prescricao_olho_direito_pris_h_value: "",
        prescricao_olho_direito_base_h: "",
        prescricao_olho_direito_base_h_value: "",
        prescricao_olho_direito_pris_v: "",
        prescricao_olho_direito_pris_v_value: "",
        prescricao_olho_direito_base_v: "",
        prescricao_olho_direito_base_v_value: "",
      });
    }

    if (this.props.order.frame_modelo) {
      this.props.handleOrderChange({
        frame_tipo_arm_value: "",
        frame_info_arm_vertical: "",
        frame_info_arm_horizontal: "",
        frame_info_arm_diametro: "",
        frame_info_arm_ponte: "",
        frame_modelo: "",
        frame_modelo_v2: "",
      });
    }
  }
  cleanFields() {
    this.props.handleOrderChange({
      lente_corteLabel: "",
      tratamentoLabel: "",
      lente_servicos: "",
      lente_coloracao: "",
      lente_coloracao_cod: "",
      lente_coloracao_value: "",
      lente_corte: "",
      lente_corte_cod: "",
      lente_corte_value: "",
      cor: "",
      cor_value: "",
      cor_Value: [],
      cor_cod: "",
      lente_servicos_cod: "",
      lente_servicos_value: "",
      cores: [],
      cor_obrigatoria: false,
      quantidade: 1,
    });
  }
  getPromoData() {
    this.context.showLoading("Validando Cupom...");

    this.setState({
      voucher_nok: false,
      voucher_ok: false,
      used_voucher: false,
      invalid_voucher: false,
      cancel_voucher: false,
    });

    this.props.handleOrderChange({ tooltip_msg: "" });

    let requestOptions = null;

    if (this.context.userSession.modulos.includes("Consulta Cupom")) {
      requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: this.props.laboratorio,
          otica:
            this.props.order.dados_empresa_faturamento_cnpj &&
            this.props.order.dados_empresa_faturamento_cnpj.replaceAll(".", "").replace("/", "").replace("-", ""),
        },
      };
    } else if (this.state.redeOticas && this.context.userSession.redeOticas) {
      requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: this.props.laboratorio,
          otica: this.props.order.dados_empresa_faturamento_cnpj,
        },
      };
    } else {
      requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: this.props.laboratorio,
        },
      };
    }

    if (!this.props.order.promo_code) {
      this.context.hideLoading();
      return;
    }

    const url = process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/vouchers/" + this.props.order.promo_code;

    fetch(url, requestOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          return response.json().then(json => {
            let error_msg = "";

            if (json[0].mensagem.includes("laboratório")) {
              error_msg = "Cupom inválido para o laboratório.";
            } else {
              error_msg = "Cupom inválido para a ótica.";
            }

            this.setState({
              voucher_nok: true,
              voucher_ok: false,
              invalid_voucher: true,
            });

            this.props.handleOrderChange({
              tooltip_msg: error_msg,
            });

            this.context.hideLoading();
          });
        } else if (response.status === 500) {
          Alert.fire({
            text: "Erro interno no Essilor Promo. Favor tentar novamente mais tarde.",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });

          this.setState({
            voucher_nok: true,
            voucher_ok: false,
            invalid_voucher: true,
          });

          this.context.hideLoading();
        } else {
          this.setState({
            voucher_nok: true,
            voucher_ok: false,
            invalid_voucher: true,
          });

          this.props.handleOrderChange({
            tooltip_msg: "Cupom inválido.",
          });

          this.context.hideLoading();
        }
      })
      .then(json => {
        if (json) {
          var cancel_voucher = false;
          if (json.status.descricao.toUpperCase().includes("CANCELADO")) {
            this.setState({ cancel_voucher: true });
            cancel_voucher = true;
          }

          let pares = [];
          var hasRpl = false;
          var hasntRplAux = true;

          json.pares.map((item, index) => {
            if (hasntRplAux && item.hasOwnProperty("produto") && item.produto.hasOwnProperty("rpl") && item.produto.rpl) {
              hasRpl = true;
            } else {
              hasRpl = false;
              hasntRplAux = false;
            }
          });

          for (var i = 0; i < json.pares.length; i++) {
            var pushObj = {
              id_par: json.pares[i].idPar,
              id_pedido_otica: json.pares[i].status,
              rpl: json.pares[i].produto.rpl,
              valorProducao: json.pares[i].produto.tipoProducao,
              valorServico: json.pares[i].servicos[0] ? json.pares[i].servicos[0].codigoRef : "",
              codigoRefServicos: json.pares[i].servicos[0] ? json.pares[i].servicos[0].codigoRef : "",
              descricao: json.pares[i].produto.descricao ? json.pares[i].produto.descricao : "",
              descricaoServico: json.pares[i].servicos[0] ? json.pares[i].servicos[0].descricao : "",
              tipoProducao: json.pares[i].produto.tipoProducao ? json.pares[i].produto.tipoProducao : "",
              servicoAssociado: json.pares[i].produto.servicoAssociado ? json.pares[i].produto.servicoAssociado : "",
            };

            pares.push(pushObj);
          }

          this.setState({ pares: pares });

          var par_atual = pares
            .map(function (e) {
              return e.id_pedido_otica;
            })
            .indexOf("ORDER_UNUSED");

          if (par_atual !== -1 && hasRpl && !cancel_voucher) {
            if (pares[0].id_pedido_otica === "ORDER_USED" && pares[1].id_pedido_otica === "ORDER_UNUSED" && localStorage.getItem("info_compl")) {
              const data = JSON.parse(localStorage.getItem("info_compl"));

              if (data.filter(el => el.cupom === this.state.promo_code).length > 0) {
                const cupomData = data.find(el => el.cupom === this.state.promo_code);

                if (!json.consumidor.nome?.trim().length > 0) {
                  document.getElementById("dados_nome_cliente").value.length === 0 &&
                    (document.getElementById("dados_nome_cliente").value = cupomData.consumidor_nome);
                  this.props.handleOrderChange({
                    dados_nome_cliente: cupomData.consumidor_nome,
                  });
                }

                if (!json.consumidor.cpf?.trim().length > 0) {
                  document.getElementById("dados_cpf_cliente").value.length === 0 &&
                    (document.getElementById("dados_cpf_cliente").value = cupomData.consumidor_cpf);
                  this.props.handleOrderChange({
                    dados_cpf_cliente: cupomData.consumidor_cpf,
                  });
                }

                if (!json.dados_nome_medico?.trim().length > 0) {
                  document.getElementById("dados_nome_medico").value.length === 0 &&
                    (document.getElementById("dados_nome_medico").value = cupomData.medico_nome);
                  this.props.handleOrderChange({
                    dados_nome_medico: cupomData.medico_nome,
                  });
                }

                if (!json.dados_crm_medico?.trim().length > 0) {
                  document.getElementById("dados_crm_medico").value.length === 0 && (document.getElementById("dados_crm_medico").value = cupomData.medico_crm);
                  this.props.handleOrderChange({
                    dados_crm_medico: cupomData.medico_crm,
                  });
                }
              }
            }

            //tratando os valores da receita

            let direito_esferico =
              (json.pares[par_atual].olhoDireito.grauEsferico < 0 ? "" : "+") + json.pares[par_atual].olhoDireito.grauEsferico.toFixed(2).toString();
            let direito_cilindrico = json.pares[par_atual].olhoDireito.grauCilindro.toFixed(2);
            let direito_eixo = json.pares[par_atual].olhoDireito.eixo.toString();
            let direito_adicao =
              json.pares[par_atual].produto.familia === "VISÃO SIMPLES"
                ? ""
                : "+".concat(json.pares[par_atual].olhoDireito.adicao.toFixed(2)).replace(",", ".");
            let direito_dnp = json.pares[par_atual].olhoDireito.dnp;
            let direito_altura = json.pares[par_atual].olhoDireito.altura;

            let esquerdo_esferico =
              (json.pares[par_atual].olhoEsquerdo.grauEsferico < 0 ? "" : "+") + json.pares[par_atual].olhoEsquerdo.grauEsferico.toFixed(2).toString();
            let esquerdo_cilindrico = json.pares[par_atual].olhoEsquerdo.grauCilindro.toFixed(2);
            let esquerdo_eixo = json.pares[par_atual].olhoEsquerdo.eixo.toString();
            let esquerdo_adicao =
              json.pares[par_atual].produto.familia === "VISÃO SIMPLES"
                ? ""
                : "+".concat(json.pares[par_atual].olhoEsquerdo.adicao.toFixed(2)).replace(",", ".");
            let esquerdo_dnp = json.pares[par_atual].olhoEsquerdo.dnp;
            let esquerdo_altura = json.pares[par_atual].olhoEsquerdo.altura;

            if (direito_cilindrico === "0.00") {
              direito_cilindrico = "";
              direito_eixo = "";
            }

            if (esquerdo_cilindrico === "0.00") {
              esquerdo_cilindrico = "";
              esquerdo_eixo = "";
            }

            if (esquerdo_esferico === "+0.00") {
              esquerdo_esferico = "0.00";
            }

            if (direito_esferico === "+0.00") {
              direito_esferico = "0.00";
            }

            if (direito_altura === 0) {
              direito_altura = "";
            }

            if (esquerdo_altura === 0) {
              esquerdo_altura = "";
            }

            this.props.handleOrderChange({
              pares: pares,
              promo_ok: true,
              voucher_ok: true,

              codigoRefServicos: json.pares[par_atual].servicos[0] ? json.pares[par_atual].servicos[0].codigoRef : "",
              rpl: json.pares[par_atual].produto.rpl,
              dados_num_pedido: json.pares[par_atual].idPedidoOtica ? json.pares[par_atual].idPedidoOtica : "",
              dados_nome_cliente: this.props.order.dados_nome_cliente ? this.props.order.dados_nome_cliente : json.consumidor.nome,

              dados_cpf_cliente: json.consumidor.cpf ? json.consumidor.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : "",
              dados_cpf_cliente_mask: json.consumidor.cpf ? json.consumidor.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : "",

              dados_promo_nome: json.campanha.nome,

              dados_nome_medico: "",
              dados_crm_medico: json.consumidor.crm ? json.consumidor.crm : "",

              valorProducao: json.pares[par_atual].produto.tipoProducao,
              valorServico: json.pares[par_atual].servicos[0] ? json.pares[par_atual].servicos[0].codigoRef : "",

              promo_code: document.getElementById("promo_code").value,
              promo_n_par: json.pares[par_atual].idPar,
              promo_total_pares: json.pares.length,

              prescricao_olhos: "ambos",

              prescricao_olho_direito_esferico: direito_esferico,
              prescricao_olho_direito_cilindrico: direito_cilindrico,
              prescricao_olho_direito_eixo: direito_eixo,
              prescricao_olho_direito_adicao: direito_adicao,
              prescricao_olho_direito_dnp: direito_dnp,
              prescricao_olho_direito_altura: direito_altura,

              prescricao_olho_esquerdo_esferico: esquerdo_esferico,
              prescricao_olho_esquerdo_cilindrico: esquerdo_cilindrico,
              prescricao_olho_esquerdo_eixo: esquerdo_eixo,
              prescricao_olho_esquerdo_adicao: esquerdo_adicao,
              prescricao_olho_esquerdo_dnp: esquerdo_dnp,
              prescricao_olho_esquerdo_altura: esquerdo_altura,
            });

            var telefone = "";
            if (json.consumidor.telefone) {
              telefone =
                json.consumidor.telefone.length > 10
                  ? json.consumidor.telefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1)$2-$3")
                  : json.consumidor.telefone.replace(/^(\d\d)(\d{4})(\d{4}).*/, "($1)$2-$3");
            }
            this.props.handleOrderChange({
              dados_email: json.consumidor.email ? json.consumidor.email : "",
              dados_nascimento: json.consumidor.dataNascimento ? json.consumidor.dataNascimento : "",
              dados_telefone: telefone,
            });
            // }

            this.setState({
              voucher_ok: true,
              voucher_nok: false,
              promo_code: document.getElementById("promo_code").value,
            });

            if (!json.pares[par_atual].idPedidoOtica) {
              document.getElementById("dados_num_pedido").value = "";
            }

            if (json.paresQtd > 1) {
              this.props.handleOrderChange({
                tooltip_msg: `Este é o ${json.pares[par_atual].idPar}º par de lentes do seu cupom.`,
              });
            }
          } else if (!hasRpl) {
            this.setState({
              voucher_nok: true,
              voucher_ok: false,
              invalid_voucher: true,
            });
            this.props.handleOrderChange({
              tooltip_msg: "Cupom inválido.",
            });
          } else {
            this.setState({
              voucher_nok: true,
              voucher_ok: false,
              used_voucher: true,
            });
            this.props.handleOrderChange({
              tooltip_msg: json.status.descricao.toUpperCase().includes("CANCELADO") ? "Cupom cancelado." : "Cupom já utilizado.",
            });
          }

          this.context.hideLoading();
        } else {
          this.context.hideLoading();
        }
      });
  }

  applyVoucher() {
    if (
      (document.getElementById("promo_code").value !== "" && document.getElementById("promo_code").value !== this.state.promo_code) ||
      (document.getElementById("promo_code").value !== "" && this.state.voucher_nok)
    ) {
      this.getPromoData();

      this.setState({
        promo_code: document.getElementById("promo_code").value,
      });
    } else if (document.getElementById("promo_code").value === "") {
      this.cleanPromo();

      this.setState({
        voucher_nok: false,
        voucher_ok: false,
        promo_code: document.getElementById("promo_code").value,
      });
    }
  }

  onBlurPromo() {
    document.getElementById("promo_code").value = "";
    this.setState({
      voucher_nok: false,
      voucher_ok: false,

      promo_code: "",
    });
    this.props.handleOrderChange({
      voucher_ok: false,
      promo_code: "",
      tooltip_msg: null,
    });
    this.cleanPromo();
  }

  render() {
    let contextAux = this.context;
    const { order } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/web-ordering/" />;
    }

    return (
      <section className={`x-web-ordering-pedido__dados ${order.direction === "right" ? "right" : "left"} content`}>
        <div className="order-main-container">
          <form ref={el => (this.myFormRef = el)}>
            <p className="order-paragraph right">*Campos obrigatórios</p>

            {this.hasPromo() && order.promo && (
              <>
                <h2 className="order-title">Cupom Promocional</h2>
                <small style={{ fontSize: "0.75rem" }}>
                  Apenas cupons da plataforma <strong>Essilor Promo</strong>.
                </small>
                <br />
                <small style={{ fontSize: "0.75rem" }}>O cupom precisa ser aplicado neste campo para que o pedido receba o preço promocional.</small>
              </>
            )}

            {this.hasPromo() && order.promo && (
              <div className="x-web-ordering-pedido__dados--card">
                <Input
                  name="promo_code"
                  type="text"
                  label="Cupom Promocional"
                  width="33%"
                  aria="Preencha o código promocional"
                  autocomplete="off"
                  value={order.promo_code}
                  readOnly={order.voucher_ok || order.promo_proximo_par > 1 ? true : false}
                  onChange={this.handleChange}
                  tooltip="Caso possua, insira aqui o cupom gerado dentro da plataforma Essilor Promo."
                />

                {order.promo_proximo_par < 1 && (
                  <div className="button-mobile-only">
                    <CustomButton
                      label={order.voucher_ok ? "Remover" : "Aplicar"}
                      width="120px"
                      marginTop="25px"
                      orange_button={true}
                      onClick={order.voucher_ok ? this.onBlurPromo : this.applyVoucher}
                    />
                  </div>
                )}

                {this.state.voucher_ok && <p className="order-status-voucher green">&#10003; Cupom aplicado.</p>}
                {this.state.voucher_nok && <p className="order-status-voucher red">&#10007; Cupom inválido.</p>}

                {order.tooltip_msg && <p className="tooltip-voucher">{order.tooltip_msg}</p>}
              </div>
            )}

            {order.tooltip_msg && <br />}

            <br />

            {order.voucher_ok && (
              <div className="warn_message_voucher">
                <Error />
                <p>
                  Algumas informações recuperadas do cupom podem ser editáveis.
                  <br />
                  Revise todos os campos para garantir a passagem correta do pedido.
                </p>
              </div>
            )}

            <h2 className="order-title">Insira os dados do pedido e solicitante</h2>

            <div className="x-web-ordering-pedido__dados--card">
              <Input
                name="dados_num_pedido"
                type="text"
                label="Nº do pedido da ótica*"
                aria="Preencha nº do pedido"
                width="33%"
                autocomplete="off"
                value={order.dados_num_pedido}
                onChange={this.handleChange}
              />

              {this.context.userSession.modulos.includes("Consulta Cupom") ? (
                <>
                  <Input
                    name="dados_empresa_faturamento"
                    type="text"
                    label="Empresa de faturamento*"
                    width="67%"
                    aria="Preencha a Empresa de faturamento"
                    value={order.dados_empresa_faturamento_cnpj}
                    onChange={this.handleChange}
                    max="18"
                    mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                  />
                </>
              ) : (
                <>
                  {(!this.state.redeOticas || !contextAux.userSession.redeOticas) && (
                    <Input
                      name="dados_empresa_faturamento"
                      type="text"
                      label="Empresa de faturamento*"
                      width="67%"
                      aria="Preencha a Empresa de faturamento"
                      value={order.dados_empresa_faturamento}
                      readOnly={true}
                    />
                  )}
                  {this.state.redeOticas && contextAux.userSession.redeOticas && (
                    <Select
                      label="Empresa de faturamento*"
                      width="67%"
                      name="dados_empresa_faturamento"
                      aria="Selecione a Empresa de faturamento"
                      itens={this.state.redes}
                      value={order.dados_empresa_faturamento_cnpj}
                      onChange={this.handleChange}
                      placeholder={this.state.placeholder}
                      placeholder_dont_display={true}
                      disabled={this.state.voucher_ok ? true : false}
                      required
                    />
                  )}
                </>
              )}
            </div>

            <div className="x-web-ordering-pedido__dados--card">
              {this.state.has_fidelity && (
                <Input
                  type="number"
                  name="codigoParticipante"
                  label="Código do Participante"
                  width="33%"
                  aria="Código do Participante"
                  tooltip="Informe o seu número do programa de fidelidade do laboratório (caso participe)."
                  value={order.codigoParticipante}
                  onChange={this.handleChange}
                />
              )}
            </div>

            <br />

            <h2 className="order-title">Informações do cliente</h2>

            <div className="x-web-ordering-pedido__dados--card">
              {/* {this.props.crm_enable && (
                <CRMSwitch label="Adicionar ao Relacionamento Digital" name="addCrm" id="addCrm" value={order.addCrm} onClick={this.handleSwicthChange} />
              )} */}
            </div>

            <div className="x-web-ordering-pedido__dados--card">
              <Input
                name="dados_nome_cliente"
                type="text"
                label={"Nome do cliente"}
                width="67%"
                aria="Preencha o nome cliente"
                onChange={this.handleChange}
                value={order.dados_nome_cliente}
              />

              <Input
                name="dados_cpf_cliente"
                type="text"
                label={"CPF do cliente"}
                width="33%"
                aria="Preencha CPF do cliente"
                onChange={this.handleChange}
                value={order.dados_cpf_cliente}
                mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
              />
            </div>

            <div className="x-web-ordering-pedido__dados--card">
              <Input
                name="dados_nome_medico"
                type="text"
                label="Nome do médico"
                width="67%"
                aria="Preencha nome do médico"
                onChange={this.handleChange}
                value={order.dados_nome_medico}
              />

              <Input
                name="dados_crm_medico"
                type="text"
                label="CRM do médico"
                width="33%"
                aria="Preencha CRM do médico"
                onChange={this.handleChange}
                value={order.dados_crm_medico}
              />
            </div>

            {/* {order.addCrm && (
              <div className="x-web-ordering-pedido__dados--card">
                <Input
                  name="dados_email"
                  type="email"
                  label={order.dados_telefone ? "E-mail" : "E-mail*"}
                  width="33.5%"
                  aria="Preencha o e-mail"
                  onChange={this.handleChange}
                  value={order.dados_email}
                />

                <Input
                  name="dados_nascimento"
                  type="text"
                  label="Data de Nascimento"
                  width="33.5%"
                  aria="Preencha a data de nascimento"
                  onChange={this.handleChange}
                  value={order.dados_nascimento}
                  mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                />

                <Input
                  name="dados_telefone"
                  type="text"
                  label={order.dados_email ? "Telefone" : "Telefone*"}
                  width="33%"
                  aria="Preencha o telefone"
                  onChange={this.handleChange}
                  value={order.dados_telefone}
                  mask={["(", /\d/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                />
              </div>
            )} */}

            <div className="order-next-button detail">
              <CustomButton label="Próximo" width="11.125rem" icon_arrow_right onClick={this.handleClickNext} />
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default OrderDetail;
