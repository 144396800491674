import React, { Component } from "react";

//Import CSS

//Import internal components
import CustomButton from "../../../components/buttons/button";
import TermosCompra from "./buy-terms";
//Import external components
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import moment from "moment";

//Import images
import Pdf from "./pdf/TERMOS E CONDIÇÕES DE VENDA DE PRODUTOS ESSILOR PARA ÓTICAS.pdf";
import AppContext from "../../../appContext";

class OrderConfirm extends Component {
  constructor(props) {
    super(props);

    OrderConfirm.contextType = AppContext;

    this.state = {
      name: this.props.order.name,
      redirect: false,
      jsonBody: this.props.order.json,
      lab: this.props.order.laboratorio,
      labName: this.props.order.labName,
      open: false,
      orderSummary: {
        name: this.props.order.name,
        lab: this.props.order.laboratorio,
        labName: this.props.order.labName,
        dados_num_pedido: this.props.order.dados_num_pedido,
        dados_empresa_faturamento: this.props.order.dados_empresa_faturamento,
        dados_empresa_faturamento_cnpj: this.props.order.dados_empresa_faturamento_cnpj,
        dados_nome_cliente: this.props.order.dados_nome_cliente,
        dados_cpf_cliente: this.props.order.dados_cpf_cliente,
        dados_cpf_cliente_mask: this.props.order.dados_cpf_cliente_mask,
        dados_nome_medico: this.props.order.dados_nome_medico,
        dados_crm_medico: this.props.order.dados_crm_medico,
        dados_promo_nome: this.props.order.dados_promo_nome,
        control_dnpPerto: this.props.order.control_dnpPerto,
        control_prescricao_ini_clientes: this.props.order.control_prescricao_ini_clientes,
        control_prescricao_dis_leitura: this.props.order.control_prescricao_dis_leitura,
        control_prescricao_cvp: this.props.order.control_prescricao_cvp,
        control_prescricao_cro: this.props.order.control_prescricao_cro,
        control_prescricao_ang_pantoscopico: this.props.order.control_prescricao_ang_pantoscopico,
        control_prescricao_curva_arm: this.props.order.control_prescricao_curva_arm,
        control_prescricao_coeficiente: this.props.order.control_prescricao_coeficiente,
        control_prescricao_estabilidade: this.props.order.control_prescricao_estabilidade,
        control_prescricao_dis_prog: this.props.order.control_prescricao_dis_prog,
        control_prescricao_dis_vert: this.props.order.control_prescricao_dis_vert,
        control_prescricao_olho_dom: this.props.order.control_prescricao_olho_dom,
        control_horizontal: this.props.order.control_horizontal,
        control_vertical: this.props.order.control_vertical,
        control_ponte: this.props.order.control_ponte,
        control_diagonal: this.props.order.control_diagonal,
        control_modelo: this.props.order.control_modelo,
        lente_tp_producao_value: this.props.order.lente_tp_producao_value,
        lente_tp_produto_value: this.props.order.lente_tp_produto_value,
        lente_esc_produto_nome: this.props.order.lente_esc_produto_nome,
        lente_servicos_value: this.props.order.lente_servicos_value,
        lente_coloracao_value: this.props.order.lente_coloracao_value,
        cor_value: this.props.order.cor_value,
        lente_corte_value: this.props.order.lente_corte_value,
        lente_montagem_value: this.props.order.lente_montagem_value,
        prescricao_olhos: this.props.order.prescricao_olhos,
        prescricao_olho_esquerdo_esferico: this.props.order.prescricao_olho_esquerdo_esferico,
        prescricao_olho_esquerdo_cilindrico: this.props.order.prescricao_olho_esquerdo_cilindrico,
        prescricao_olho_esquerdo_eixo: this.props.order.prescricao_olho_esquerdo_eixo,
        prescricao_olho_esquerdo_adicao: this.props.order.prescricao_olho_esquerdo_adicao,
        prescricao_olho_direito_esferico: this.props.order.prescricao_olho_direito_esferico,
        prescricao_olho_direito_cilindrico: this.props.order.prescricao_olho_direito_cilindrico,
        prescricao_olho_direito_eixo: this.props.order.prescricao_olho_direito_eixo,
        prescricao_olho_direito_adicao: this.props.order.prescricao_olho_direito_adicao,
        prescricao_prisma: this.props.order.prescricao_prisma,
        prescricao_olho_esquerdo_pris_h: this.props.order.prescricao_olho_esquerdo_pris_h,
        prescricao_olho_esquerdo_base_h: this.props.order.prescricao_olho_esquerdo_base_h,
        prescricao_olho_esquerdo_pris_v: this.props.order.prescricao_olho_esquerdo_pris_v,
        prescricao_olho_esquerdo_base_v: this.props.order.prescricao_olho_esquerdo_base_v,
        prescricao_olho_direito_pris_h: this.props.order.prescricao_olho_direito_pris_h,
        prescricao_olho_direito_base_h: this.props.order.prescricao_olho_direito_base_h,
        prescricao_olho_direito_pris_v: this.props.order.prescricao_olho_direito_pris_v,
        prescricao_olho_direito_base_v: this.props.order.prescricao_olho_direito_base_v,
        prescricao_olho_esquerdo_dnp: this.props.order.prescricao_olho_esquerdo_dnp,
        prescricao_olho_esquerdo_altura: this.props.order.prescricao_olho_esquerdo_altura,
        prescricao_olho_esquerdo_dnp_perto: this.props.order.prescricao_olho_esquerdo_dnp_perto,
        prescricao_olho_direito_dnp: this.props.order.prescricao_olho_direito_dnp,
        prescricao_olho_direito_altura: this.props.order.prescricao_olho_direito_altura,
        prescricao_olho_direito_dnp_perto: this.props.order.prescricao_olho_direito_dnp_perto,
        prescricao_ini_clientes: this.props.order.prescricao_ini_clientes,
        prescricao_dis_leitura: this.props.order.prescricao_dis_leitura,
        prescricao_cvp: this.props.order.prescricao_cvp,
        prescricao_cro_direito: this.props.order.prescricao_cro_direito,
        prescricao_cro_esquerdo: this.props.order.prescricao_cro_esquerdo,
        prescricao_ang_pantoscopico: this.props.order.prescricao_ang_pantoscopico,
        prescricao_curva_arm: this.props.order.prescricao_curva_arm,
        prescricao_coeficiente: this.props.order.prescricao_coeficiente,
        prescricao_estabilidade: this.props.order.prescricao_estabilidade,
        prescricao_dis_prog: this.props.order.prescricao_dis_prog,
        prescricao_dis_vert_direito: this.props.order.prescricao_dis_vert_direito,
        prescricao_dis_vert_esquerdo: this.props.order.prescricao_dis_vert_esquerdo,
        prescricao_olho_dom: this.props.order.prescricao_olho_dom,
        frame_tipo_arm_value: this.props.order.frame_tipo_arm_value,
        frame_info_arm_vertical: this.props.order.frame_info_arm_vertical,
        frame_info_arm_horizontal: this.props.order.frame_info_arm_horizontal,
        frame_info_arm_diametro: this.props.order.frame_info_arm_diametro,
        frame_info_arm_ponte: this.props.order.frame_info_arm_ponte,
        frame_modelo: this.props.order.frame_modelo,
        frame_modelo_v2: this.props.order.frame_modelo_v2,
        descricao: this.props.order.descricao,
        valorLiquido: this.props.order.valorLiquido,
        promo_code: this.props.order.promo_code,
        remoteEdgeDisabled: this.props.order.remoteEdgeDisabled,
      },
    };

    this.openTermosCompra = this.openTermosCompra.bind(this);
    this.hideTermosCompra = this.hideTermosCompra.bind(this);
    this.enviarPedido = this.enviarPedido.bind(this);
  }

  handleClickPrev = e => {
    e.preventDefault();
    this.props.onNext(4, "left");
  };

  handleChange = evt => {
    this.props.handleOrderChange({ [evt.target.name]: evt.target.value });
  };

  confirm = e => {
    e.preventDefault();
    this.enviarPedido();
  };

  //remoção do crm
  // createCostumerOrderSalesForce() {
  //   const { order } = this.props;

  //   const requestOptions = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       accountCode: this.context.userSession.conta,
  //       costumer: order.costumer,
  //       order: order.salesOrder,
  //     }),
  //     headers: new Headers({
  //       Authorization: "Bearer " + localStorage.getItem("session_token"),
  //     }),
  //   };

  //   fetch(process.env.REACT_APP_LABS_API + "create_salesForce_order.php", requestOptions)
  //     .then(response => {
  //       return response.json();
  //     })
  //     .then(json => {
  //       if (json.status === 201 || json.status === 200) {
  //         this.context.showSnack("Cliente criado no CRM com sucesso", "success");
  //         this.context.hideLoading();
  //       } else {
  //         this.context.showSnack(json.status + " - " + json.message, "error");
  //         this.context.hideLoading();
  //       }
  //     });
  // }

  enviarPedido() {
    const { order } = this.props;

    if (this.state !== undefined) {
      var requestOptions = {};

      if (order.redeOticas && this.state.orderSummary.dados_empresa_faturamento_cnpj) {
        requestOptions = {
          method: "POST",
          headers: {
            access_token: localStorage.getItem("token"),
            laboratorio: this.state.lab,
            otica: this.state.orderSummary.dados_empresa_faturamento_cnpj,
          },
          body: JSON.stringify(this.state.jsonBody),
        };
      } else {
        requestOptions = {
          method: "POST",
          headers: {
            access_token: localStorage.getItem("token"),
            laboratorio: this.state.lab,
          },
          body: JSON.stringify(this.state.jsonBody),
        };
      }

      this.context.showLoading("Enviando pedido...");
      fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/pedidos", requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status >= 400 && response.status <= 499) {
            return response.json().then(json => {
              this.context.hideLoading();
              Swal.fire({
                html: json[0].mensagem + 
                '<br><br>Em caso de dúvidas, entre em contato com o laboratório.',
                icon: "warning",
                title: "Erro ao criar o pedido",
                width: "600px",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              }).then(result => {});
            });
          } else if (response.status === 504) {
            this.context.hideLoading();
            Swal.fire({
              html: "A requisição atingiu o tempo máximo de processamento. Tente novamente mais tarde.<br><br>Em caso de dúvidas, entre em contato com o laboratório.",
              icon: "warning",
              title: "Aviso",
              width: "600px",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            }).then(result => {});
          } else {
            this.context.hideLoading();
            return response.json().then(json => {
              this.context.hideLoading();
              Swal.fire({
                html: "Tente novamente mais tarde.<br><br>Em caso de dúvidas, entre em contato com o laboratório.",
                icon: "warning",
                title: "Erro interno",
                width: "600px",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              }).then(result => {});
            });
          }
        })
        .then(json => {
          if (json) {
            var date1 = moment(json.orcamento.dataEntregaPrevista).format("DD/MM/YYYY");
            var value = "R$" + json.orcamento.valorLiquido;

            this.setState({
              pedido: json.id,
              prazo: date1,
              valor: value,
              newOrder: "/web-ordering",
              newOrderPromo: "/order",
            });

            //remoção do crm
            // if (order.addCrm) {
            //   // CRM
            //   //Salvando informações de clientes e pedidos no CRM
            //   this.createCostumerOrderSalesForce();
            // } else {
            this.setState({ redirect: true });
            this.context.hideLoading();
          }
        });
    }
    // }
  }

  openTermosCompra(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      this.setState({ open: true });
    });
  }

  hideTermosCompra(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      this.setState({ open: false });
    });
  }

  render() {
    const { order } = this.props;

    if (this.state.redirect) {
      return (
        
        <Redirect
          to={{
            pathname: "/confirmed",
            state: {
              jsonBody: this.state.jsonBody,
              labName: this.state.labName,
              lab: this.state.lab,
              orderSummary: this.state.orderSummary,
              parametros: order.json_parametros,
              isCrm: order.addCrm,
              // costumer: order.costumer,
              // salesOrder: order.salesOrder,
              redeOticas: order.redeOticas,
              dados_empresa_faturamento: order.dados_empresa_faturamento,
              dados_empresa_faturamento_cnpj: order.dados_empresa_faturamento_cnpj ? order.dados_empresa_faturamento_cnpj : order.accountCode,
              promo_code: order.promo_code,
              rpl: order.rpl,
              promo_n_par: order.promo_n_par,
              promo_total_pares: order.promo_total_pares,
              IsCor_AR: order.IsCor_AR,
              detailOrder: order.detailOrder,
              logo: order.labLogo,
              IsGrowmocao: order.IsGrowmocao,
              hasCorteRemoto: order.hasCorteRemoto,
              pedido: this.state.pedido,
              prazo: this.state.prazo,
              valor: this.state.valor,
              newOrder: this.state.newOrder,
              newOrderPromo: this.state.newOrderPromo,
              colorirNaOtica: order.colorirNaOtica,
            },
          }}
        />
      );
    }

    if (this.state.redirectWO) {
      return <Redirect to="/web-ordering" />;
    }

    return (
      <main className="x-confirm-order__main">
        <div className="x-confirm-order__container">
          <section className="x-confirm-order__left">
            <div className="x-confirm-order__dados--container">
              <div className="x-confirm-order__dados--intro">
                <h1 className="x-confirm-order__dados--title">Confirme seu pedido</h1>
                <span className="x-confirm-order__dados--square"></span>
                <p className="x-confirm-order__dados--paragraph">Olá, {this.state.name}.</p>
                {!this.context.userSession.modulos.includes("Pedidos Simulação") ? (
                  <p className="x-confirm-order__dados--paragraph">
                    Seu pedido foi validado e está pronto para ser enviado ao laboratório.
                    <br />
                    Revise atentamente o resumo do pedido ao lado e clique em confirmar pedido para concluí-lo.
                  </p>
                ) : (
                  <p className="x-confirm-order__dados--paragraph">
                    Simulação de pedido concluída.
                    <br />
                  </p>
                )}
              </div>
              <div className="x-confirm-order__list">
                {order.valor_flag && (
                  <ul className="x-confirm-order__dados--list">
                    <li className="x-confirm-order__dados--item is--large">Valor total do pedido</li>
                    <li className="x-confirm-order__dados--item blue">{order.valorLiquido}</li>
                  </ul>
                )}
                <ul className="x-confirm-order__dados--list">
                  <li className="x-confirm-order__dados--item is--large">Data de saída estimada</li>
                  <li className={`x-confirm-order__dados--item ${order.valor_flag ? "" : "blue"}`}>{order.prazo}</li>
                </ul>
              </div>
              <div className="x-confirm-order__dados--intro">
                {!this.context.userSession.modulos.includes("Pedidos Simulação") && (
                  <>
                    <p className="x-confirm-order__dados--paragraph_small">
                    Ao clicar em confirmar o pedido, você concorda com o envio das informações que constam no resumo lateral e com os{" "}
                      <a href={Pdf} target="_blank">
                        termos e condições de compra
                      </a>
                      .
                    </p>
                  </>
                )}
              </div>
              {/* <a className="x-confirm-order__dados--view" href="/order" target="_blanck">Pré-visualizar</a> */}
              <div className="order-buttons order-buttons-confirm">
                <div className="order-prev-button">
                  <CustomButton label="Corrigir" width="11.125rem" icon_arrow_left orange_button onClick={this.handleClickPrev.bind(this)} />
                </div>
                {!this.context.userSession.modulos.includes("Pedidos Simulação") && (
                  <>
                    <div className="order-next-button confirm">
                      <CustomButton label="Confirmar pedido" width="13.125rem" icon_arrow_right onClick={this.confirm} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
        <div className={`${this.state.open == true ? "show-termoscompra" : "hide-termoscompra"}`}>
          <div className="termoscompra-close" onClick={this.hideTermosCompra}>
            <svg xmlns="http://www.w3.org/2000/svg" className="close-desk" width="17.089" height="17.119" viewBox="0 0 17.089 17.119">
              <path
                className="a"
                d="M20.862,36.982a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434l6.072-6.072,6.072,6.072a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434,1.432,1.432,0,0,0,0-2.053l-6.072-6.072,6.072-6.072A1.452,1.452,0,0,0,35,20.731L28.929,26.8l-6.043-6.014a1.452,1.452,0,1,0-2.053,2.053L26.9,28.915l-6.043,6.043a1.4,1.4,0,0,0,0,2.024Z"
                transform="translate(-20.399 -20.297)"
                fill="#b5b5b5"
              />
            </svg>
          </div>
          <TermosCompra />
        </div>
      </main>
    );
  }
}

export default OrderConfirm;
